<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader
        text="Добавить экспертов к выбранному заболеванию (состоянию)"
      />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Поиск"
        hide-details
        clearable
        class="mb-6"
      ></v-text-field>
      <UsersListWithSelect :users="searchedGvsUsers" />
    </div>
    <div v-else>Нет пользователей для отображения</div>
  </DisplayLoading>
</template>

<script>
import UsersListWithSelect from '@/components/project/UsersListWithSelect.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_USERS } from '@/store/const/apiPaths'
import { USER_GVS_ID } from '@/store/const/userRoles'

export default {
  data() {
    return {
      search: '',
      loadingMessage: 'Загружаю пользователей',
      loadUrl: API_USERS,
      errorMessage: 'Не удалось загрузить пользователей',
    }
  },
  components: {
    UsersListWithSelect,
    PageHeader,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    gvsUsers() {
      return this.pageData
        .filter(u => u.role.id === USER_GVS_ID)
        .map(u => ({
          ...u,
          searchString: [u.fio, u.profiles.map(p => p.name)]
            .join(',')
            .toLowerCase(),
        }))
    },
    searchedGvsUsers() {
      if (!this.search) return this.gvsUsers
      const search = this.search.toLowerCase()
      return this.gvsUsers.filter(u => u.searchString.includes(search))
    },
  },
}
</script>

<style scoped>
.v-input >>> .v-label,
.v-input >>> .v-text-field__slot {
  padding-left: 24px;
}
</style>
